<template>
    <div class="home">
        <van-nav-bar class="navbar" title="许可证申请列表" left-text="返回" :right-text="softName" left-arrow
            @click-left="onClickLeft" />
        <van-search v-model="searchValue" show-action placeholder="请输入订单信息/申请项目/机器码" @search="onSearch">
            <template #action>
                <van-icon name="clock-o" size="20" />
            </template>
        </van-search>
        <van-tabs v-model="activeTab" sticky color="#2A82E4">
            <van-tab title="待使用">
                <div class="totalContent">
                    <!-- <div class="chooseAll">
                        <div class="allText">选择全部</div>
                        <van-checkbox v-model="allChecked" shape="square"></van-checkbox>
                    </div> -->
                    <div class="itemContent" v-for="(item, index) in orderList" :key="item.id">
                        <div class="applyTitle">
                            <div class="applyTime">
                                申请时间：-
                            </div>
                            <van-checkbox v-model="item.checked" shape="square" @change="checkedState"
                                :disabled="isDisableChoose(item, index)"></van-checkbox>
                        </div>
                        <div class="applyContent">
                            <div class="orderMes">
                                <div class="orderHead">订单信息：</div>
                                <div class="orderDes">{{ item.ordernum }}{{ item.ordername }}</div>
                            </div>
                            <div class="orderMes">
                                <div class="orderHead">申请项目：</div>
                                <div class="orderDes">{{ getProductName(item.productid) }}</div>
                            </div>
                            <div class="orderMes">
                                <div class="orderHead">订阅时长：</div>
                                <div class="orderDes">{{ getDuration(item) }}</div>
                            </div>
                            <div class="orderMes">
                                <div class="orderHead">机器码：</div>
                                <div class="orderDes">{{ urlData.id }}</div>
                            </div>

                        </div>
                        <!-- <div class="applyOps">
                            <el-button size="small">取消</el-button>
                            <el-button size="small" type="primary" @click="applyLicense(item)">提交申请</el-button>
                        </div> -->
                    </div>

                </div>
                <!-- <div class="applyAll">
                    <el-button type="primary" class="applyallBtn" @click="applyAll">全部申请</el-button>
                </div> -->
                <!-- 按钮 -->

            </van-tab>
            <van-tab title="可下载">
                <div class="totalContent">
                    <div class="itemContent" v-for="item in showLicenseList" :key="item.id">
                        <div class="applyTitle">
                            <div class="applyTime">
                                申请时间：{{ item.creatortime }}
                            </div>
                            <div class="applyState">已通过</div>
                        </div>
                        <div class="applyContent">
                            <div v-for="e in item.orderList" style="padding-bottom: 10px;" :key="e.id">
                                <div class="orderMes">
                                    <div class="orderHead">订单信息：</div>
                                    <div class="orderDes">{{ e.ordername }}</div>
                                </div>
                                <div class="orderMes">
                                    <div class="orderHead">申请项目：</div>
                                    <div class="orderDes">{{ getProductName(e.productid) }}</div>
                                </div>
                                <div class="orderMes">
                                    <div class="orderHead">订阅时长：</div>
                                    <div class="orderDes">{{ getDuration(e) }}</div>
                                </div>
                            </div>

                            <div class="orderMes">
                                <div class="orderHead">机器码：</div>
                                <div class="orderDes">{{ item.machinecode }}</div>
                            </div>

                        </div>
                        <div class="applyOps">
                            <el-button size="small" type="primary" @click="downloadLicense(item)">下载许可证</el-button>
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <!-- <van-loading size="24px" v-show="loading">加载中...</van-loading> -->
        <div class="stickyBottom" v-show="activeTab == 0">
            <!-- <van-button type="primary">基础用法</van-button> -->
            <el-button size="middle" type="primary" style="width: 90%;" @click="applyAll()">下载许可证</el-button>
        </div>
    </div>
</template>

<script>
import { getUserIdByPhone, getLicensesByPhone, getAllProduction, downloadLicense, getOrdersByPhone, makeSingleLicense, makeLabStart } from "@/assets/API/pay.js";
import "amfe-flexible/index";
import { Dialog, Toast } from 'vant';
import define from '@/utils/define';
export default {
    name: "Home",
    data() {
        return {
            softName: '',
            activeTab: 0,
            searchValue: '',
            checked: false,
            urlData: {
                id: ''
            },
            allChecked: false,
            productList: [],
            licenseList: [],
            showLicenseList: [],
            orderList: [],
            detailProductIds: [], // 已存在的产品id
            checked: [], // 选中的产品
            // machineCode: '',
            // phoneNum: '',
            // name: '',
            // company: '',
            // inviteCode: '',
            // des: '',
            loading: true,
            labOrder: []
        };
    },
    components: {
        [Dialog.Component.name]: Dialog.Component,
    },
    mounted() {
        this.urlData = this.$route.query;
        const toast = Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
        // 获取所有产品信息
        let query = {
            pageSize: 100
        };
        getAllProduction(query).then(res => {
            this.productList = res.data.list;
        });
        // this.machineCode = this.$route.query.id;
        // this.phoneNum = this.$route.query.phoneNum;
        // this.name = this.$route.query.name;
        // this.company = this.$route.query.company;
        // this.inviteCode = this.$route.query.inviteCode;
        // this.des = this.$route.query.des;
        // this.urls = this.$route.query;
        if (this.urlData.pid == undefined) {
            // this.xkShow = false;
            // this.phone = this.urls.phone;
            // this.exhibit = false;
            // this.querys();
        } else {
            if (this.urlData.pid == 1) {
                if (this.urlData.type == 2) {
                    this.softName = 'SummitMapTrial';
                } else {
                    this.softName = "SummitMap";
                }
            } else if (this.urlData.pid == 2) {
                this.softName = "SummitSolid";
            } else if (this.urlData.pid == 4) {
                this.softName = "SummitLab";
            } else if (this.urlData.pid == 5) {
                this.softName = "SummitMapHUBEI";
            } else if (this.urlData.pid == 6) {
                this.softName = "SummitLab";
            } else if (this.urlData.pid == 7) {
                this.softName = "SummitSolidSGJ";
            } else if (this.urlData.pid == 8) {
                this.softName = "SummitTerra";
            } else if (this.urlData.pid == 10) {
                this.softName = "SummitViewPro";
            }
        }

        if (this.urlData.phoneNum) {
            getUserIdByPhone(this.urlData.phoneNum).then(res => {
                if (res.code != 400) {
                    this.userData = res.data;
                    // 获取已生成过的许可证
                    getLicensesByPhone(this.userData.id).then(res => {
                        this.licenseList = res.data.list.filter(e => {
                            return e.machinecode == this.urlData.id && e.producttype == this.softName && e.licensetype == 1;
                        });
                        this.detailProductIds = this.licenseList.map(item => {
                            return item.productid;
                        });
                        // 合并同一个软件的许可证信息
                        // let baseTypeMethond = [];
                        this.licenseList.forEach(e => {
                            // if (baseTypeMethond.includes(e.producttype + e.purchasemethod)) {
                            //     var d = this.showLicenseList.find(function (i) {
                            //         return i.producttype == e.producttype && i.purchasemethod == e.purchasemethod;
                            //     });
                            //     d.orderList.push(e);
                            //     d.creatortime = e.creatortime;
                            // } else {
                            let pushData = e;
                            pushData.orderList = [e];
                            this.showLicenseList.push(pushData);
                            // baseTypeMethond.push(e.producttype + e.purchasemethod);
                            // }
                        });
                        // this.showLicenseList = this.licenseList;
                        // 同一个许可证申请信息中 合并同一个订单信息 todo?
                        console.log(this.showLicenseList, 'www');
                    }).then(res => {
                        // 获取待生成的订单产品信息
                        getOrdersByPhone(this.userData.id).then(res => {
                            let orderlist = res.data.list;
                            let resultOrderList = [];
                            orderlist.forEach(e => {
                                if (e.purchasemethod == 'online') {
                                    e.orderdetaillist.forEach(ele => {
                                        let detailData = ele;
                                        detailData.ordername = e.ordername;
                                        detailData.ordernum = e.ordernum;
                                        detailData.purchasemethod = e.purchasemethod;
                                        detailData.checked = false;
                                        // if (!detailIds.includes(detailData.productid)) {
                                        if (detailData.producttype == this.softName && detailData.licensestate != 0) {
                                            resultOrderList.push(detailData);
                                        }
                                        // 计算购买lab订单
                                        if (detailData.producttype == 'SummitLab') {

                                            this.labOrder.push(detailData);
                                        }
                                        // }
                                    });
                                }
                            });
                            console.log(resultOrderList, 'uuu');
                            if (this.urlData.pid == 6 && resultOrderList.length > 0) {
                                // 用来给lab生成启动许可
                                this.orderList = [
                                    {
                                        id: '启动数据',
                                        checked: false,
                                        ordername: 'lab启动程序'
                                    }
                                ];

                            } else {
                                this.orderList = resultOrderList;
                            }

                        }).then(res => {
                            this.loading = false;
                            toast.clear();
                        });

                    });

                } else {
                    this.loading = false;
                    toast.clear();
                    // 未找到用户
                    Dialog.alert({
                        message: '未找到用户',
                    }).then(() => {
                        // on close

                    });
                }
            });
        } else {
            this.loading = false;
            toast.clear();

            Dialog.alert({
                message: '请填写信息',
            }).then(() => {
                // on close

            });
        }

    },
    watch: {
        allChecked: function (newVal, val) {
            console.log(newVal, '1111');
            if (newVal) {
                // 全选 -- 选中
            }
        }
    },
    methods: {
        getData() {
            if (this.urlData.phoneNum) {
                this.showLicenseList = [];
                getUserIdByPhone(this.urlData.phoneNum).then(res => {
                    if (res.code != 400) {
                        this.userData = res.data;
                        // 获取已生成过的许可证
                        getLicensesByPhone(this.userData.id).then(res => {
                            this.licenseList = res.data.list.filter(e => {
                                return e.machinecode == this.urlData.id && e.producttype == this.softName && e.licensetype == 1;
                            });
                            this.detailProductIds = this.licenseList.map(item => {
                                return item.productid;
                            });
                            // 合并同一个软件的许可证信息
                            // let baseTypeMethond = [];
                            this.licenseList.forEach(e => {
                                // if (baseTypeMethond.includes(e.producttype + e.purchasemethod)) {
                                //     var d = this.showLicenseList.find(function (i) {
                                //         return i.producttype == e.producttype && i.purchasemethod == e.purchasemethod;
                                //     });
                                //     d.orderList.push(e);
                                //     d.creatortime = e.creatortime;
                                // } else {
                                let pushData = e;
                                pushData.orderList = [e];
                                this.showLicenseList.push(pushData);
                                // baseTypeMethond.push(e.producttype + e.purchasemethod);
                                // }
                            });
                            // this.showLicenseList = this.licenseList;
                            console.log(this.showLicenseList, 'www');
                        }).then(res => {
                            // 获取待生成的订单产品信息
                            getOrdersByPhone(this.userData.id).then(res => {
                                let orderlist = res.data.list;
                                orderlist.forEach(e => {
                                    if (e.purchasemethod == 'online') {
                                        e.orderdetaillist.forEach(ele => {
                                            let detailData = ele;
                                            detailData.ordername = e.ordername;
                                            detailData.ordernum = e.ordernum;
                                            detailData.purchasemethod = e.purchasemethod;
                                            detailData.checked = false;
                                            // if (!detailIds.includes(detailData.productid)) {
                                            if (detailData.producttype == this.softName && detailData.licensestate != 0) {
                                                this.orderList.push(detailData);
                                            }
                                            // }
                                        });
                                    }
                                });
                                console.log(this.orderList, 'uuu');
                            });
                        });

                    } else {
                        // 未找到用户
                        Dialog.alert({
                            message: '未找到用户',
                        }).then(() => {
                            // on close
                        });
                    }
                });
            }
        },
        onClickLeft() {
            console.log('ssssss');
            if (this.urlData.type == 2) {
                this.$router.push({
                    path: "/applyTrial", query: this.$route.query
                });
            } else {
                this.$router.push({
                    path: "/create", query: this.$route.query
                });
            }

        },
        onSearch() { },
        getProductName(data) {
            let res = this.productList.filter(e => {
                return e.id == data;
            });
            if (res[0]) {
                return res[0].name;
            } else {
                return '';
            }
        },
        downloadLicense(data) {
            // 下载许可证
            downloadLicense(data.id).then(res => {
                let url = "/api/business/C_license/downloadFile?data=" + res.data;
                if (!url) return;
                // const baseUrl = url.indexOf("http") > -1 ? "" : this.define.comUrl;
                const baseUrl = url.indexOf("http") > -1 ? "" : this.define.APIURl;
                const a = document.createElement("a");
                a.setAttribute("download", res.data);
                let name = null;
                console.log(baseUrl, 'baseurl');
                const realUrl = baseUrl + url + (name ? "&name=" + name : "");
                a.setAttribute("href", realUrl);
                a.click();
            });
        },
        getDuration(data) {
            if (data.durationunion == 'week') {
                return data.duration + "周";
            } else if (data.durationunion == 'month') {
                return data.duration + "月";
            } else if (data.durationunion == 'season') {
                return data.duration + "季";
            } else if (data.durationunion == 'halfyear') {
                return data.duration + "半年";
            } else {
                return "永久";
            }
        },
        // 单个
        applyLicense(data) {
            console.log(data, 'uuu');
            data.orderdetailproductid = data.id;
            data.machinecode = this.urlData.id;
            // 合并同一个软件数据 产品类型 + purchasemethod
            let baseData = [];
            this.licenseList.forEach(e => {
                if (e.producttype == data.producttype && e.purchasemethod == data.purchasemethod) {
                    baseData.push(e);
                }
            });
            let totalData = [...baseData, data];
            // 生成许可证
            this.createApply(totalData);

        },
        // 生成许可证方法
        createApply(totalData) {
            let idsResult = totalData.map(item => {
                return item.productid;
            });
            let featureidR = totalData.map(item => {
                return item.featureid;
            });
            let durationR = totalData.map(item => {
                return item.duration;
            });
            let durationunionR = totalData.map(item => {
                return item.durationunion;
            });
            let detailIds = totalData.map(item => {
                return item.orderdetailproductid;
            });
            let ordercodeR = totalData.map(item => {
                return item.ordercode;
            });
            let producttypeD = '';
            if (totalData[0].purchasemethod == 'online') {
                producttypeD = totalData[0].producttype + "-Online";
            } else {
                producttypeD = totalData[0].producttype;
            }
            console.log(totalData, '------');
            let data = {
                productid: idsResult.toString(),
                featureid: featureidR.toString(),
                producttype: producttypeD,
                state: 0,
                filedata: '',
                duration: durationR.toString(),
                durationunion: durationunionR.toString(),
                machinecode: totalData[0].machinecode,
                contactphone: '',
                contactname: '',
                companyname: '',
                ordercode: ordercodeR.toString(),
                guestmes: '',
                endtime: '',
                creatoruserid: '',
                orderuserid: this.userData.id,
                orderusername: '',
                orderdetailproductid: detailIds.toString(),
                licensetype: 1
            };
            console.log(data, 'qqqqqqqqqqqqq');
            makeSingleLicense(data).then(res => {
                // 跳转到已申请页
                this.getData();
                this.activeTab = 1;
            });
        },
        applyAll() {
            // 至少有一个选中的产品
            let choosedList = this.orderList.filter(e => e.checked == true);
            if (choosedList.length == 0) {
                Dialog.alert({
                    message: '至少选中一个产品',
                });
                return;
            }
            console.log(choosedList, '++++');
            Dialog.confirm({
                message:
                    '许可证一旦生成不可撤销，不可转移，请确认机器码从订阅软件运行的电脑上获取。',
            })
                .then(() => {
                    if (this.urlData.pid != 6) {
                        choosedList.forEach(data => {
                            data.orderdetailproductid = data.id;
                            data.machinecode = this.urlData.id;
                        });

                        // 去重
                        this.createApply(choosedList);
                        setTimeout(() => {
                            if (this.showLicenseList.length > 0) {
                                this.downloadLicense(this.showLicenseList[0]);
                            }
                        }, 200);
                    } else {
                        // 申请lab启动程序
                        console.log(this.labOrder, '-------');
                        let duration = this.calBigestDuration(this.labOrder);
                        let query = {
                            machinecode: this.urlData.id,
                            duration,
                            orderuserid: this.userData.id
                        };
                        makeLabStart(query).then(res => {
                            // 跳转到已申请页
                            this.getData();
                            this.activeTab = 1;
                        });
                    }
                })
                .catch(() => {
                    // on cancel
                });



        },
        calBigestDuration(data) {
            let totalDuration = [];
            data.forEach(e => {
                if (e.durationunion == 'week') {
                    let res = Number(e.duration) * 7;
                    totalDuration.push(res);
                } else if (e.durationunion == 'month') {
                    let res = Number(e.duration) * 30;
                    totalDuration.push(res);
                } else if (e.durationunion == 'halfyear') {
                    let res = Number(e.duration) * 183;
                    totalDuration.push(res);
                } else if (e.durationunion == 'season') {
                    let res = Number(e.duration) * 90;
                    totalDuration.push(res);
                }
            });
            console.log(totalDuration, 'iii');
            return Math.max.apply(null, totalDuration);
        },
        checkedState(val) {
            // 选中产品后触发
            this.checked = [];
            this.detailProductIds = [];
            this.orderList.forEach(e => {
                if (e.checked) {
                    this.detailProductIds.push(e.productid);
                    this.checked.push(e);
                }
            });
        },
        isDisableChoose(data, index) {
            let result = false;
            // 选过的产品不可选
            if (this.detailProductIds.includes(data.productid)) {
                this.checked.forEach(e => {
                    if (e.productid == this.orderList[index].productid && e.id != this.orderList[index].id) {
                        result = true;
                    }
                });
            }
            // 筛选数据 去掉本机器码本身就有的有效的产品
            this.licenseList.forEach(e => {
                if (e.productid == data.productid && e.purchasemethod == data.purchasemethod) result = true;
            });
            return result;
        }

    },
};
</script>
<style lang="scss" scoped>
.stickyBottom {
    bottom: 10px;
    position: fixed;
    text-align: center;
    width: 100%;
}

.home {}

.totalContent {
    height: 100%;
    background-color: #FAFAFA;
    padding-bottom: 60px;

}

.itemContent {
    // height: 220px;
    opacity: 1;
    border-radius: 6px;
    background: #FFFFFF;
    margin: 10px;
    padding: 20px;

    .applyTitle {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 2px solid #F7F7F7;

        .applyTime {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 21.72px;
            color: rgba(56, 56, 56, 1);
        }

        .unactiveState {
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 21.72px;
            color: rgba(86, 196, 81, 1);
        }
    }

    .applyContent {
        padding: 10px 0;

        .orderMes {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 20.27px;
            color: rgba(56, 56, 56, 1);
            text-align: left;
            vertical-align: top;
            padding: 5px 0;

            .orderHead {
                width: 25%;
            }

            .orderDes {
                flex: 1;
                overflow: auto;
            }

        }
    }

    .applyOps {
        display: flex;
        align-items: end;
        justify-content: flex-end;
    }
}

.chooseAll {
    display: flex;
    justify-content: space-between;
    padding: 0px 30px;

    .allText {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(128, 128, 128, 1);
        text-align: left;
        vertical-align: top;
    }
}

.applyAll {
    padding: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: #FFFFFF;

    .applyallBtn {
        width: 100%;
    }
}

.navbar {
    ::v-deep .van-nav-bar__right {
        .van-nav-bar__text {
            color: #808080;
        }
    }
}
</style>